import type { PropsWithChildren, ReactNode } from 'react';
import { Container } from '~/components/container';
import { VStack } from '~/components/stack';
import { env } from '~/config/env';
import { ThemeAwareImage } from '~/features/theme/theme-aware-image';
import { ROUTES } from '~/utils/routes';

export function LandingFeatures() {
  return (
    <Container
      id={ROUTES.LANDING.FEATURES.replace('/#', '')}
      className="py-16 lg:py-32 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-16"
    >
      <Feature
        title="Progress Tracking"
        imgSrc="/img/landing/features/progress.png"
      >
        Track your progress and identify areas for improvement with performance
        analytics.
      </Feature>
      <Feature
        title="Daily Goal"
        imgSrc={
          <ThemeAwareImage
            light={`/img/landing/features/daily-goal-${env.VITE_APP}-light.png`}
            dark={`/img/landing/features/daily-goal-${env.VITE_APP}-dark.png`}
            className="max-w-[170px]"
            alt="Daily Goal"
          />
        }
      >
        Keep your study routine on track by setting and achieving daily question
        targets.
      </Feature>
      <Feature
        title="Simulate Real Exam"
        imgSrc="/img/landing/features/real-exam.png"
      >
        Simulate the real test experience with comprehensive practice exams.
      </Feature>
    </Container>
  );
}

interface FeatureProps {
  imgSrc: ReactNode;
  imgClassName?: string;
  title: string;
}

function Feature({
  imgSrc,
  imgClassName,
  title,
  children,
}: PropsWithChildren<FeatureProps>) {
  return (
    <VStack className="gap-5 items-center text-center">
      {typeof imgSrc === 'string' ? (
        <img loading="lazy" src={imgSrc} alt={title} className={imgClassName} />
      ) : (
        imgSrc
      )}
      <h3 className="text-2xl font-semibold">{title}</h3>
      <p className="leading-relaxed">{children}</p>
    </VStack>
  );
}
