import { Container } from '~/components/container';
import { VStack } from '~/components/stack';
import { env } from '~/config/env';
import { LandingCTAButton } from '~/routes/_index/landing-cta-button';

export function LandingPractice() {
  return (
    <div className="bg-card relative flex justify-end pt-10 lg:pt-44">
      <Container className="lg:absolute lg:z-10 lg:inset-0 lg:flex lg:items-center">
        <VStack className="gap-10 max-w-[600px]">
          <h2 className="font-bold text-2xl lg:text-4xl max-w-[400px]">
            Practice with 1,500+ exam style questions
          </h2>
          <p className="text-lg">
            Test your knowledge with 1,500+ high yield multiple choice questions
            <b>aligned with the most recent exam guidelines</b>. Get{' '}
            <b>instant explanations</b> so you can better understand and review
            even the most complex concepts.
          </p>
          <LandingCTAButton />
        </VStack>
      </Container>
      <img
        loading="lazy"
        src={`/img/landing/practice-${env.VITE_APP}.png`}
        alt="Practice"
        className="w-1/2 hidden lg:block"
      />
    </div>
  );
}
